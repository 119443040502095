import React from 'react';
import { shape } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { NavLink } from 'react-router-dom';
import { MdAccountBox, MdExitToApp } from 'react-icons/md';
import { BsPersonFillGear } from 'react-icons/bs';
import {
  useAuth,
  useAuthDispatch,
} from '@bit/medicalwebexperts.mwe-ui.auth-context';
import BaseSidebar from '@bit/medicalwebexperts.mwe-ui.sidebar';
import Avatar from '@bit/medicalwebexperts.mwe-ui.avatar';
import List from '@bit/medicalwebexperts.mwe-ui.list';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Box from '@bit/medicalwebexperts.mwe-ui.box';

import logo from '../../../assets/images/logo.svg';

const propTypes = {
  state: shape({}).isRequired,
};

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
});

const Logo = styled.div(
  css({
    img: {
      maxWidth: '100%',
    },
    my: 4,
    px: 4,
  }),
);

const Profile = styled.div(
  css({
    alignItems: 'center',
    bg: 'blackAlpha.50',
    display: 'flex',
    mx: 4,
    mt: 4,
    mb: 10,
    p: 4,
    borderRadius: 'md',
  }),
);

const ListItem = styled(List.Item)(
  css({
    '&:hover': {
      bg: 'blackAlpha.50',
    },
    '&.ListItem--active': {
      bg: 'blackAlpha.100',
      borderRightColor: 'accent',
    },
    borderRightColor: 'transparent',
    borderRightStyle: 'solid',
    borderRightWidth: '6px',
    color: 'text',
    cursor: 'pointer',
    fontFamily: 'body',
    fontSize: 'sm',
    fontWeight: 'medium',
    p: 4,
  }),
);

const sidebarItems = [
  {
    label: 'Customers',
    to: '/customers',
    icon: <MdAccountBox size={22} />,
  },
  {
    label: 'Accounts',
    to: '/accounts',
    icon: <BsPersonFillGear size={22} />,
  },
];

const Sidebar = (props) => {
  const { user } = useAuth();
  const { logout } = useAuthDispatch();

  return (
    <BaseSidebar {...props} classes={{ root: { border: 'none' } }}>
      <Wrapper>
        <Logo>
          <img src={logo} alt="Medical Web Experts" />
        </Logo>
        <Profile>
          <Avatar name={user.name} size="xs" />
          <Box pl={4}>
            <Typography variant="body2" fontWeight="bold">
              {user.name}
            </Typography>
          </Box>
        </Profile>
        <List as="nav" flexGrow="1">
          {sidebarItems.map((item) => (
            <ListItem
              key={item.to}
              as={NavLink}
              to={item.to}
              onClick={() => props.state.hide()}
              activeClassName="ListItem--active"
            >
              <List.Item.Icon color="accent">{item.icon}</List.Item.Icon>
              {item.label}
            </ListItem>
          ))}
        </List>
        <ListItem as="a" onClick={logout}>
          <List.Item.Icon color="danger">
            <MdExitToApp size={22} />
          </List.Item.Icon>
          Logout
        </ListItem>
      </Wrapper>
    </BaseSidebar>
  );
};

Sidebar.propTypes = propTypes;

export default Sidebar;
