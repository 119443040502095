import React from 'react';
import { node } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Card from '@bit/medicalwebexperts.mwe-ui.card';

import logo from '../../../assets/images/logo.svg';

const propTypes = {
  children: node.isRequired,
};

const Main = styled.main(
  css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '90vh',
    px: 4,
    width: '100%',
  }),
);

const Logo = styled.img(
  css({
    display: 'block',
    mb: 2,
    mx: 'auto',
    maxWidth: 250,
  }),
);

const LoggedOutLayout = ({ children }) => (
  <Main>
    <Card minWidth="300px" maxWidth="340px" minHeight="330px">
      <Card.Body>
        <Logo src={logo} alt="Valley Medical Laboratory" />
        {children}
      </Card.Body>
    </Card>
  </Main>
);

LoggedOutLayout.propTypes = propTypes;

export default LoggedOutLayout;
