import client from './client';

const authClient = {
  async authenticate(credentials) {
    const { access, refresh } = await client.post('/auth/token/', credentials);
    client.$setToken(access, refresh);

    const user = await client.get('/users/me/');

    return { user };
  },
  async logout() {
    client.$clearToken();
  },
  async reAuthenticate() {
    if (!client.$getToken() && !client.$getRefreshToken()) {
      throw new Error('Missing auth tokens');
    }

    const user = await client.get('/users/me/');

    return { user };
  },
};

export default authClient;
