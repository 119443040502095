import React, { useEffect, Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ProgressBar from '@bit/medicalwebexperts.mwe-ui.progress-bar';

import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import LoggedOutLayout from './components/Layout/LoggedOutLayout/LoggedOutLayout';
import LoggedInLayout from './components/Layout/LoggedInLayout/LoggedInLayout';
import PrivateRoute from './components/Routes/PrivateRoute';
import PublicRoute from './components/Routes/PublicRoute';

const LoginPage = lazy(() => import('./pages/Login'));
const CustomerListPage = lazy(() =>
  import('./pages/Customers/CustomerListPage/CustomerListPage'),
);
const AccountListPage = lazy(() => import('./pages/Accounts/index'));

const CustomerDetailPage = lazy(() =>
  import('./pages/Customers/CustomerDetail/CustomerDetailPage'),
);

const CreateAccount = lazy(() =>
  import('./pages/Customers/CustomerDetail/Accounts/CreateAccount'),
);
const EditAccount = lazy(() =>
  import('./pages/Customers/CustomerDetail/Accounts/EditAccount'),
);

const NotFoundPage = lazy(() => import('./pages/404'));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Loader = (props) => (
  <Suspense
    {...props}
    fallback={
      <ProgressBar
        classes={{
          root: { position: 'absolute', top: 0, left: 0 },
        }}
        size="sm"
      />
    }
  />
);

const App = () => (
  <Router>
    <Helmet titleTemplate="%s - Medical Web Experts Customers Portal" />
    <ScrollToTop />
    <ErrorBoundary>
      <Switch>
        {/* Public routes */}
        <PublicRoute path="/account">
          <LoggedOutLayout>
            <Loader>
              <Switch>
                <Route path="/account/login" exact>
                  <LoginPage />
                </Route>
                <Redirect to="/account/login" />
              </Switch>
            </Loader>
          </LoggedOutLayout>
        </PublicRoute>

        {/* Private routes */}
        <PrivateRoute path="/">
          <LoggedInLayout>
            <Loader>
              <Switch>
                {/* Customers */}
                <Route path="/customers">
                  <Switch>
                    <Route path="/customers" exact>
                      <CustomerListPage />
                    </Route>
                    <Route path="/customers/:id" exact>
                      <CustomerDetailPage />
                    </Route>
                    <Route path="/customers/:id/account/new">
                      <CreateAccount />
                    </Route>

                    <Route path="/customers/:id/account-view/:accountId">
                      <EditAccount />
                    </Route>

                    <Redirect to="/customers" />
                  </Switch>
                </Route>

                {/* Accounts */}
                <Route path="/accounts">
                  <Switch>
                    <Route path="/accounts" exact>
                      <AccountListPage />
                    </Route>
                    <Route path="/accounts/account-view/:accountId">
                      <EditAccount />
                    </Route>
                    <Route path="/accounts/:id/account/new">
                      <CreateAccount />
                    </Route>
                    <Redirect to="/accounts" />
                  </Switch>
                </Route>

                {/* 404 */}
                <Route path="*">
                  <NotFoundPage />
                </Route>
              </Switch>
            </Loader>
          </LoggedInLayout>
        </PrivateRoute>
      </Switch>
    </ErrorBoundary>
  </Router>
);

export default App;
