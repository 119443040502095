import React from 'react';
import { node } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { MdMenu } from 'react-icons/md';
import { useUp } from '@bit/medicalwebexperts.mwe-ui.utils.styled';
import BaseSidebar, {
  useSidebarState,
} from '@bit/medicalwebexperts.mwe-ui.sidebar';
import AppBar from '@bit/medicalwebexperts.mwe-ui.app-bar';
import IconButton from '@bit/medicalwebexperts.mwe-ui.icon-button';
import Box from '@bit/medicalwebexperts.mwe-ui.box';

import logo from '../../../assets/images/logo.svg';

import Sidebar from './Sidebar';

const propTypes = {
  children: node.isRequired,
};

const Main = styled.main(({ theme }) =>
  css({
    ml: [null, null, null, theme.sizes.sidebar],
    p: 8,
    position: 'relative',
    pt: [24, 24, 24, 8],
  }),
);

const Toolbar = styled.div(
  css({
    alignItems: 'center',
    display: 'flex',
    minHeight: 16,
    px: 4,
  }),
);

const LoggedInLayout = ({ children }) => {
  const showAppBar = !useUp('lg');
  const sidebar = useSidebarState({ animated: true });

  return (
    <>
      <Sidebar expand="lg" state={sidebar} ariaLabel="Main Menu" />
      <Main>
        {showAppBar && (
          <AppBar variant="default">
            <Toolbar>
              <BaseSidebar.Disclosure {...sidebar}>
                {(props) => (
                  <IconButton size="lg" color="inherit" {...props}>
                    <MdMenu />
                  </IconButton>
                )}
              </BaseSidebar.Disclosure>
              <Box flexGrow="1" ml={2}>
                <img src={logo} width="225" alt="Medical Web Experts" />
              </Box>
            </Toolbar>
          </AppBar>
        )}
        {children}
      </Main>
    </>
  );
};

LoggedInLayout.propTypes = propTypes;

export default LoggedInLayout;
