import preset from '@bit/medicalwebexperts.mwe-ui.themes.preset';

export default {
  ...preset,
  fonts: {
    ...preset.fonts,
    body: 'Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
    heading:
      'Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
  },
  colors: {
    ...preset.colors,
    background: '#f9f9f9',
    primary: {
      light: '#2D95E5',
      main: '#2D95E5',
      dark: '#2D95E5',
    },
    secondary: {
      light: '#FF9930',
      main: '#FF9930',
      dark: '#FF9930',
    },
    accent: '#FF3885',
  },
  sizes: {
    ...preset.sizes,
    sidebar: '240px',
  },
};
